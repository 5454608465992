import React, { useState } from "react";
import "./App.css";
import { frageFragen, ichHabNochNieFragen } from "./questions";

function App() {
  const [spieler1, setSpieler1] = useState("");
  const [spieler2, setSpieler2] = useState("");
  const [punkte, setPunkte] = useState({ spieler1: 0, spieler2: 0 });
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [showGame, setShowGame] = useState(false);
  const [message, setMessage] = useState("");

  const startGame = () => {
    if (spieler1 && spieler2) {
      setShowGame(true);
      const firstPlayer = Math.random() < 0.5 ? "spieler1" : "spieler2";
      setCurrentPlayer(firstPlayer);
      setMessage(`${firstPlayer === "spieler1" ? spieler1 : spieler2} beginnt!`);
    } else {
      alert("Bitte beide Spielernamen eingeben.");
    }
  };

  const handleChoice = (choice) => {
    if (choice === "Ich hab noch nie") {
      const frage = ichHabNochNieFragen[Math.floor(Math.random() * ichHabNochNieFragen.length)];
      setMessage(frage);

      setPunkte((prevPunkte) => ({
        ...prevPunkte,
        [currentPlayer]: prevPunkte[currentPlayer] + 1,
      }));
      const nextPlayer = currentPlayer === "spieler1" ? "spieler2" : "spieler1";
      setCurrentPlayer(nextPlayer);
    } else if (punkte[currentPlayer] > 0) {
      const frage = frageFragen[Math.floor(Math.random() * frageFragen.length)];
      setMessage(frage);

      setPunkte((prevPunkte) => ({
        ...prevPunkte,
        [currentPlayer]: prevPunkte[currentPlayer] - 1,
      }));

      const nextPlayer = currentPlayer === "spieler1" ? "spieler2" : "spieler1";
      setCurrentPlayer(nextPlayer);
    } else {
      setMessage("Du hast keine Punkte. Du musst 'Ich hab noch nie' wählen.");
    }
  };

  return (
    <div className="App">
      {!showGame ? (
        <div className="setup-container">
          <h2>Spieler Namen</h2>
          <input
            className="input-field"
            placeholder="Spieler 1 Name"
            value={spieler1}
            onChange={(e) => setSpieler1(e.target.value)}
          />
          <input
            className="input-field"
            placeholder="Spieler 2 Name"
            value={spieler2}
            onChange={(e) => setSpieler2(e.target.value)}
          />
          <button className="start-button" onClick={startGame}>Spiel starten</button>
        </div>
      ) : (
        <div className="game-container">
          <h3>{message}</h3>
          <h4>
            {spieler1}: {punkte.spieler1} Punkte | {spieler2}: {punkte.spieler2} Punkte
          </h4>
          <div>
            {currentPlayer === "spieler1" ? spieler1 : spieler2} ist am Zug
          </div>
          <button className="game-button" onClick={() => handleChoice("Ich hab noch nie")}>
            Ich hab noch nie
          </button>
          <button
            className="game-button"
            onClick={() => handleChoice("Frage")}
            disabled={punkte[currentPlayer] === 0}
          >
            Frage
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
