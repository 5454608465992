// questions.js
export const ichHabNochNieFragen = [
    "Ich hab noch nie aus Versehen den falschen Namen gesagt.",
    "Ich hab noch nie Nacktfotos verschickt.",
    "Ich hab noch nie etwas im Bett ausprobiert, das ich bereue.",
    "Ich hab noch nie beim Sex gelacht.",
    "Ich hab noch nie jemanden beim Sex gehört.",
    "Ich hab noch nie eine Liebesnachricht an die falsche Person geschickt.",
    "Ich hab noch nie eine romantische Verabredung vergessen.",
    "Ich hab noch nie jemanden absichtlich eifersüchtig gemacht.",
    "Ich hab noch nie jemanden nackt gesehen, den ich nicht sehen wollte.",
    "Ich hab noch nie einen Text zurückgerufen, den ich gesendet habe.",
    "Ich hab noch nie einen Kuss bereut.",
    "Ich hab noch nie einen One-Night-Stand gehabt.",
    "Ich hab noch nie jemanden in der Öffentlichkeit geküsst.",
    "Ich hab noch nie beim Flirten übertrieben.",
    "Ich hab noch nie über eine peinliche Date-Erfahrung gelacht.",
    "Ich hab noch nie ein Nacktbild gemacht.",
    "Ich hab noch nie jemanden aus Neugier gestalkt.",
    "Ich hab noch nie beim Tanzen jemanden beeindruckt.",
    "Ich hab noch nie eine Lüge erzählt, um besser dazustehen.",
    "Ich hab noch nie jemandem gesagt, dass ich ihn liebe, ohne es zu meinen.",
    "Ich hab noch nie in der Öffentlichkeit zu viel getrunken.",
    "Ich hab noch nie jemanden aus Versehen beleidigt.",
    "Ich hab noch nie jemanden verführt.",
    "Ich hab noch nie ein Nacktbild von jemand anderem gesehen.",
    "Ich hab noch nie jemanden beim Umziehen beobachtet.",
    "Ich hab noch nie ein Geheimnis in der Beziehung preisgegeben.",
    "Ich hab noch nie erotische Fantasien gestanden.",
    "Ich hab noch nie jemandem beim Flirten zugeschaut.",
    "Ich hab noch nie jemanden nur wegen seines Aussehens gedatet.",
    "Ich hab noch nie eine heimliche Schwärmerei gestanden.",
    "Ich hab noch nie ein Kompliment erhalten, das ich nicht mochte.",
    "Ich hab noch nie ein geheimes Date gehabt.",
    "Ich hab noch nie von jemandem geträumt, den ich nicht mag.",
    "Ich hab noch nie eine Beziehung online gestartet.",
    "Ich hab noch nie jemanden angelogen, um mich besser darzustellen.",
    "Ich hab noch nie eine falsche Telefonnummer gegeben.",
    "Ich hab noch nie jemanden aus Versehen angeschrieben.",
    "Ich hab noch nie eine unangemessene Nachricht geschickt.",
    "Ich hab noch nie jemanden auf einer Party geküsst.",
    "Ich hab noch nie jemanden absichtlich ignoriert.",
    "Ich hab noch nie heimlich jemanden beobachtet.",
    "Ich hab noch nie ein Date abgesagt, ohne Grund zu nennen.",
    "Ich hab noch nie über ein früheres Date gelacht.",
    "Ich hab noch nie jemanden beim Schlafen beobachtet.",
    "Ich hab noch nie einen Flirtversuch zurückgewiesen.",
    "Ich hab noch nie ein Date verpasst.",
    "Ich hab noch nie jemanden für seinen Stil verurteilt.",
    "Ich hab noch nie auf ein Date gewartet, das nicht kam.",
    "Ich hab noch nie aus Versehen ein Date verdorben.",
    "Ich hab noch nie in Gedanken etwas Peinliches gestanden.",
    "Ich hab noch nie jemanden gedatet, den ich eigentlich nicht attraktiv fand.",
    "Ich hab noch nie beim Küssen die Kontrolle verloren.",
    "Ich hab noch nie versucht, jemanden eifersüchtig zu machen.",
    "Ich hab noch nie auf einer Party jemanden wild geküsst.",
    "Ich hab noch nie jemanden nur zum Spaß geküsst.",
    "Ich hab noch nie eine Lüge über meine Dating-Erfahrung erzählt.",
    "Ich hab noch nie jemanden auf der Arbeit attraktiv gefunden.",
    "Ich hab noch nie eine Fantasie über einen Kollegen gehabt.",
    "Ich hab noch nie versucht, jemanden zu beeindrucken, indem ich cool war.",
    "Ich hab noch nie jemanden in meiner Nähe umarmt, um eifersüchtig zu machen.",
    "Ich hab noch nie jemanden geküsst, nur um mich besser zu fühlen.",
    "Ich hab noch nie jemanden im Club angesprochen.",
    "Ich hab noch nie jemanden geküsst, den ich am selben Tag kennengelernt habe.",
    "Ich hab noch nie jemanden aus Versehen nackt gesehen.",
    "Ich hab noch nie jemanden gefragt, ob er Single ist.",
    "Ich hab noch nie jemanden aus Eifersucht ignoriert.",
    "Ich hab noch nie eine Verabredung bereut.",
    "Ich hab noch nie einen romantischen Abend geplant.",
    "Ich hab noch nie jemanden absichtlich verwirrt.",
    "Ich hab noch nie eine Beziehung mit einem Freund begonnen.",
    "Ich hab noch nie beim ersten Date über die Zukunft gesprochen.",
    "Ich hab noch nie jemanden angelächelt, der mich angelächelt hat.",
    "Ich hab noch nie jemanden getroffen, den ich online kennengelernt habe.",
    "Ich hab noch nie versucht, eine Ex-Partner zurückzugewinnen.",
    "Ich hab noch nie jemanden verletzt, ohne es zu wollen.",
    "Ich hab noch nie jemanden nur aus Neugier gedatet.",
    "Ich hab noch nie jemanden betrogen.",
    "Ich hab noch nie eine Liebeserklärung erhalten.",
    "Ich hab noch nie versucht, aus einer Beziehung auszubrechen.",
    "Ich hab noch nie eine geheime Affäre gehabt.",
    "Ich hab noch nie mit jemandem Schluss gemacht und es bereut.",
    "Ich hab noch nie jemanden beim Flirten erwischt.",
    "Ich hab noch nie eine Nacht durchgetanzt.",
    "Ich hab noch nie versucht, eine Beziehung zu retten.",
    "Ich hab noch nie einen peinlichen Kuss gehabt.",
    "Ich hab noch nie jemandem gesagt, dass ich ihn vermisse.",
    "Ich hab noch nie jemanden aus Frust geküsst.",
    "Ich hab noch nie jemanden beim Tanzen verführt.",
    "Ich hab noch nie jemanden zum Lachen gebracht, der eigentlich wütend war.",
    "Ich hab noch nie heimlich von jemandem geschwärmt.",
    "Ich hab noch nie jemanden aus Versehen beleidigt.",
    "Ich hab noch nie eine romantische Überraschung vorbereitet.",
    "Ich hab noch nie jemanden geküsst, den ich später bereut habe.",
    "Ich hab noch nie jemanden eifersüchtig gemacht, ohne es zu wollen.",
    "Ich hab noch nie jemandem meine Gefühle gestanden.",
    "Ich hab noch nie jemanden heimlich beobachtet.",
    "Ich hab noch nie eine heimliche Affäre gehabt.",
    "Ich hab noch nie jemanden aus Verlegenheit angelogen.",
    "Ich hab noch nie jemanden angelächelt, den ich nicht kannte.",
    "Ich hab noch nie ein Date geplant und abgesagt.",
    "Ich hab noch nie jemandem ein peinliches Kompliment gemacht."
];

export const frageFragen = [
    "Was ist dein größter geheim gehaltener Wunsch in einer Beziehung?",
    "Was war das Verrückteste, das du je für jemanden getan hast?",
    "Hast du eine geheime Fantasie, die du noch nie geteilt hast?",
    "Was macht dich in einer Beziehung am meisten an?",
    "Was ist deine Meinung zu offenen Beziehungen?",
    "Hast du schon mal von jemandem geträumt, der dich überrascht hätte?",
    "Was ist das Erotischste, das du je erlebt hast?",
    "Welcher Körperteil an dir ist deiner Meinung nach am attraktivsten?",
    "Was hältst du von Rollenspielen in Beziehungen?",
    "Hast du eine geheime Fantasie, die dich schockiert hat?",
    "Was ist das Abenteuerlichste, das du dir im Bett vorstellen kannst?",
    "Gibt es etwas, das du immer mal ausprobieren wolltest, aber nie den Mut hattest?",
    "Was ist das Wichtigste, das dir in einer Partnerschaft fehlt?",
    "Gibt es eine Fantasie, die du für deinen Partner ausleben würdest?",
    "Hast du jemals über eine Affäre nachgedacht?",
    "Was ist das Ungewöhnlichste, das du an einem Menschen attraktiv findest?",
    "Was würdest du in einer Beziehung nie tun?",
    "Welches Geheimnis hast du vor deinem Partner?",
    "Wie wichtig ist dir Treue in einer Beziehung?",
    "Hast du jemals jemanden geküsst, den du nicht hättest küssen sollen?",
    "Hast du dich jemals aus Eifersucht dumm verhalten?",
    "Würdest du jemals für einen Partner umziehen?",
    "Wie stellst du dir dein perfektes Date vor?",
    "Was war das Erotischste, das du je gesagt hast?",
    "Würdest du für die Liebe auf Sex verzichten?",
    "Was denkst du über intime Fotos?",
    "Wie wichtig ist dir Intimität in einer Beziehung?",
    "Was war das peinlichste Kompliment, das du je gemacht hast?",
    "Hast du eine heimliche Schwäche für bestimmte Worte?",
    "Welches Verhalten kann dir das Interesse an jemandem nehmen?",
    "Worauf könntest du in einer Beziehung nie verzichten?",
    "Was ist das Langweiligste, das dir jemals im Bett passiert ist?",
    "Hattest du jemals Gefühle für jemanden, den du eigentlich nicht mögen solltest?",
    "Was ist deine Meinung zu Dirty Talk?",
    "Was ist das Romantischste, das du je für jemanden gemacht hast?",
    "Was findest du an dir selbst am erotischsten?",
    "Welcher romantische Moment wird dir immer im Gedächtnis bleiben?",
    "Hast du jemals jemanden verführt?",
    "Was hältst du von dem ersten Kuss in einer Beziehung?",
    "Wie wichtig ist dir das Aussehen in einer Beziehung?",
    "Was ist das Verrückteste, das du je für Liebe getan hast?",
    "Welches geheime Detail über dich würdest du nie jemandem erzählen?",
    "Würdest du für einen Partner deine Komfortzone verlassen?",
    "Was würdest du für einen Kuss alles tun?",
    "Gibt es einen geheimen Ort, an dem du küssen möchtest?",
    "Hast du eine besondere Erinnerung an einen ersten Kuss?",
    "Gibt es einen Ort, den du als besonders romantisch empfindest?",
    "Was ist das Wichtigste, das du in einer Beziehung schätzt?",
    "Würdest du alles für deinen Partner tun?",
    "Was war das Peinlichste, das du je beim Flirten getan hast?",
    "Hast du jemals jemandem geschrieben, obwohl du wusstest, dass du es nicht solltest?",
    "Was denkst du über das Wort 'Liebe'?",
    "Hast du jemals jemanden beobachtet, ohne dass er es wusste?",
    "Hattest du schon mal Gefühle für einen besten Freund?",
    "Wie stellst du dir dein perfektes Liebesleben vor?",
    "Was ist das Geheimnis deiner erotischsten Fantasie?",
    "Hast du jemals jemanden berührt, nur um seine Reaktion zu sehen?",
    "Gibt es einen Duft, der dich an jemanden erinnert?",
    "Was hältst du von Sexting?",
    "Was ist das schönste Kompliment, das du je über dein Aussehen bekommen hast?",
    "Wie wichtig ist dir körperliche Nähe?",
    "Würdest du jemanden für seine Vergangenheit verurteilen?",
    "Was ist das wichtigste Zeichen von Zuneigung für dich?",
    "Was ist das Peinlichste, das dir in einer Beziehung passiert ist?",
    "Würdest du jemals über eine Ex-Beziehung reden?",
    "Was ist das Sinnlichste, das dir jemals passiert ist?",
    "Was ist deine geheime Schwäche im Bett?",
    "Hast du schon mal mit jemandem geflirtet, nur weil du es konntest?",
    "Was wäre dein perfekter Morgen nach einer Nacht zu zweit?",
    "Hast du schon mal jemanden geküsst, der es nicht wollte?",
    "Was ist deine Meinung zu Liebesbriefen?",
    "Gibt es etwas, das du nie jemandem erzählen würdest?",
    "Wie wichtig ist dir die Meinung anderer über deine Beziehung?",
    "Was ist das Peinlichste, das du je über einen Partner gedacht hast?",
    "Hast du schon mal jemanden wegen seines Aussehens angesprochen?",
    "Was wäre das Verrückteste, das du für deine Liebe tun würdest?",
    "Was war das schönste Erlebnis, das du je mit jemandem hattest?",
    "Was hältst du von dem ersten Mal mit einem neuen Partner?",
    "Was denkst du über romantische Gesten?",
    "Hast du jemals jemanden so geküsst, dass es ein Leben lang bleibt?",
    "Was ist das Erste, woran du bei jemandem denkst?",
    "Wie wichtig ist dir Kommunikation in einer Beziehung?",
    "Würdest du eine Beziehung aufgeben, wenn du unglücklich bist?",
    "Was ist deine größte Unsicherheit in einer Beziehung?",
    "Gibt es jemanden, den du immer vermisst hast?",
    "Hast du jemals von einer Affäre geträumt?",
    "Was ist das Wichtigste, das du je über Liebe gelernt hast?",
    "Würdest du jemals einen Partner für deinen besten Freund verlassen?",
    "Wie oft denkst du über deine Beziehung nach?",
    "Würdest du jemals etwas Illegales für deinen Partner tun?",
    "Hast du ein Geheimnis, das dich nachts wach hält?",
    "Hast du jemals das Bedürfnis gehabt, jemanden zu beschützen?",
    "Wie würdest du deinem Partner beweisen, dass du ihn liebst?",
    "Würdest du jemals eine Beziehung beenden, nur weil du nicht genug Spaß hast?",
    "Was denkst du über Eifersucht in einer Beziehung?",
    "Würdest du jemals eine heimliche Beziehung führen?",
    "Wie wichtig ist dir körperliche Treue?"
];
